import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let LoginThunk = createAsyncThunk(
	"login/LoginThunk",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.post(`/auth/login/dashboard`, {
				email: arg?.email,
				password: arg?.pass,
			});

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
