import React, { useEffect } from "react";

// icon
import {
  BlogIcon,
  CloseIcon,
  DeleteIcon,
  PlusIcon,
  SaveIcon,
  UploadImagesIcon,
} from "../../assets/icons";

import { yupResolver } from "@hookform/resolvers/yup";
import { Switch } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { setCurrentSectionDataState } from "../../RTK/Reducers/SectionsReducer";
import { AddSliderSectionThunk } from "../../RTK/Thunk/AddSliderSectionThunk";
import { AllSectionsPagThunk } from "../../RTK/Thunk/AllSectionsPagThunk";
import { OneSectionThunk } from "../../RTK/Thunk/OneSectionThunk";
import SectionLabel from "../sectionLabel/SectionLabel";
import styles from "./AddNewSlider.module.css";
import { useState } from "react";

const SwitchStyle = {
	width: "25px",
	height: "14px",
	padding: "0",
	"& .MuiButtonBase-root ": {
		color: "#fff",
		"&.Mui-checked": {
			color: "#fff",
		},

		"&:hover": {
			backgroundColor: "transparent",
		},

		"&.Mui-checked+.MuiSwitch-track": {
			backgroundColor: "#3AE374",
			opacity: "1",
		},
	},
	"& .MuiSwitch-switchBase ": {
		padding: "1px",
		"&.Mui-checked": {
			transform: "translateX(11px)",
		},
	},

	"& .MuiSwitch-thumb": {
		width: "12px",
		height: "12px",
		boxShadow: "none",
	},
};
  const schema = Yup.object().shape({
    title: Yup.string().optional("قم بإضافة اسم القسم"),
    ingredients: Yup.array()
      // test select options and radio buttons
      .of(
        Yup.object().shape({
          link: Yup.string().optional("قم بإضافة ربط الملف"),
          image: Yup.mixed().required("قم بإضافة الملف"),
          status: Yup.mixed().optional("قم بإضافة الحالة"),
        })
      ),
  });
const AddNewSlider = ({
	featuresBlocks,
	reload,
	setReload,
	showModalAddSection,
	setShowModalAddSection,
}) => {
  // this context to open and close add new question

  let { currentSectionData } = useSelector((state) => state.SectionsReducer);
  let dispatch = useDispatch();
  let params = useParams();
  // from hooks

  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      title: "",
      ingredients: [
        {
          link: "",
          image: null,
          status: true,
          previewImgOrVideo: null,
        },
      ],
    },
  });
  const {
    fields: ingredientsFields,
    append: appendIngredientField,
    remove: removeIngredientField,
  } = useFieldArray({
    control,
    name: "ingredients",
  });

  // console.log("ingredients", watch("ingredients"));

  // Function to handle file input change
  const handleSetImageInput = (blockIndex, event) => {
    const file = event?.target?.files[0];
    const imgConvert = URL.createObjectURL(file);

    const updatedBlocks = [...watch("ingredients")];
    updatedBlocks[blockIndex].image = file;

    updatedBlocks[blockIndex].previewImgOrVideo = imgConvert;

    setValue("ingredients", updatedBlocks);
  };

  // handle create edit current question

  const fileInputRef = React.createRef();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // To set current questions if isEDIT

  // get current data target updated
  useEffect(() => {
      if (showModalAddSection?.isEdit && showModalAddSection?.targetId) {
        dispatch(
          OneSectionThunk({
            id: params?.sectionId,
            targetId: showModalAddSection?.targetId,
          })
        );
      }
    },
    [showModalAddSection]
  );
  const currentSectionDataRef = React.useRef(true);
  useEffect(() => {
    if (currentSectionData !== null && currentSectionDataRef.current) {
      // console.log(currentSectionData)
      setValue("title", currentSectionData?.title||"");
      // set ingredients arr data
      removeIngredientField(0);
      currentSectionData?.details?.forEach((el, index) => {
        // console.log(`el${index + 1}`, el);
        // console.log(el?.name);
        appendIngredientField({
          link: el?.link,
          image: el?.image,
          status: el?.status,
          previewImgOrVideo: el.image,
          id: el?.id,
        });
      });

      currentSectionDataRef.current = false;
    }
  }, [currentSectionData]);
  useEffect(() => {
    return () => {
      dispatch(setCurrentSectionDataState());
    };
  }, []);

    const [targetDeleteIdItem, setTargetDeleteIdItem] = useState([]);
    let handleDeleteItem = (index) => {
      if (showModalAddSection?.targetId) {
        // watch(`ingredients.${index}.id`);
        let itemId = [...targetDeleteIdItem];
        itemId.push(watch(`ingredients.${index}.id`));
        //  console.log(watch(`ingredients.${index}.id`));
        setTargetDeleteIdItem(itemId);
      }
      removeIngredientField(index);
    };
    const [showSaveBT, setShowSaveBT] = React.useState(true);
  const onSubmit = async (data) => {
    setShowSaveBT(false)
    dispatch(
      AddSliderSectionThunk({
        //  ingredients: data,
        ...data,
        id: params?.sectionId,
        targetDeleteIdItem,

        type: "slider",
        targetId: showModalAddSection?.targetId
          ? showModalAddSection?.targetId
          : null,
      })
    )
      .unwrap()
      .then((data) => {
                    setShowSaveBT(true);

        dispatch(
          AllSectionsPagThunk({
            id: params?.sectionId,
            page: 1,
          })
        );
        setShowModalAddSection({ show: false, type: "" });
      })
      .catch((error) => {
            setShowSaveBT(true);

        if (error?.data) {
          // if (error?.data["slider.title"]) {
          //   // console.log(error?.data["slider.link"]);
          //   setError(`ingredients.${0}.title`, {
          //     message: error?.data["slider.title"],
          //   });
          // }
          watch(`ingredients`)?.forEach((el, index) => {
            if (error?.data[`sliders.${index}.link`]) {
              // console.log(error?.data["slider.link"]);
              setError(`ingredients.${index}.link`, {
                message: error?.data[`sliders.${index}.link`],
              });
            }
            if (error?.data[`sliders.${index}.status`]) {
              // console.log(error?.data["slider.link"]);
              setError(`ingredients.${index}.status`, {
                message: error?.data[`sliders.${index}.status`],
              });
            }
            if (error?.data[`sliders.${index}.image`]) {
              // console.log(error?.data["slider.link"]);
              setError(`ingredients.${index}.image`, {
                message: error?.data[`sliders.${index}.image`],
              });
            }
          });
          if (error?.data?.title) {
            setError("title", { message: error?.data?.title });
          }
        }

        // console.log(error);
        // handle error here
      });
  };
  const programAdvantageBlock = () => {
    return (
      ingredientsFields?.length > 0 && (
        <>
          {ingredientsFields.map((block, blockIndex) => {
            return (
              <>
                {/* Create program items  */}
                <section
                  key={blockIndex}
                  className="  px-[20px]  overflow-auto"
                >
                  <SectionLabel
                    label={"slider"}
                    icon={<BlogIcon className="programBlogIcon" />}
                  />

                  {/* Create item input */}

                  <div className="w-full flex-col flex justify-start items-center gap-5 mb-[40px] p-[15px] rounded-[5px] bg-unnamed_color_ebebeb">
                    <div className="flex  justify-start items-center gap-4">
                      {/* Delete image */}
                      <div
                        onClick={() => handleDeleteItem(blockIndex)}
                        // disabled={!point?.link}
                        className="cursor-pointer disabled:cursor-not-allowed"
                      >
                        <DeleteIcon className="w-6 h-6 " title="حذف الملف" />
                      </div>

                      {/* disable and enable switch */}
                      <Switch
                        title="تعطيل/تفعيل"
                        sx={SwitchStyle}
                        name="status"
                        onChange={(e) => {
                          //  e.target.value
                          setValue(
                            `ingredients.${blockIndex}.status`,
                            e.target.checked
                          );
                        }}
                        checked={watch(`ingredients.${blockIndex}.status`)}
                      />
                    </div>

                    <div className="w-full  h-12 border-[1px] border-[#E8E8E8] bg-[#FFFEF2]  rounded flex justify-start items-center">
                      <input
                        type="text"
                        placeholder={`رابط رقم ${blockIndex + 1}`}
                        {...register(`ingredients.${blockIndex}.link`)}
                        className="w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]"
                      />
                    </div>
                    {errors?.ingredients &&
                      errors?.ingredients[blockIndex]?.link && (
                        <div className=" w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured">
                          <span>
                            {errors?.ingredients[blockIndex]?.link?.message}
                          </span>
                        </div>
                      )}
                    {/* Delete and switch buttons */}
                    <div className="w-full  h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed border-unnamed_color_119184  m-auto mb-1 cursor-pointer">
                      <input
                        type="file"
                        accept="image/*"
                        control={control}
                        onChange={(selectedImageOrVideo) => {
                          // console.log(
                          //   "selectedImageOrVideo",
                          //   selectedImageOrVideo
                          // );
                          // event?.target?.files[0];
                          if (selectedImageOrVideo?.target?.files[0]) {
                            setError(`ingredients.${blockIndex}.image`);
                            handleSetImageInput(
                              blockIndex,
                              selectedImageOrVideo
                            );
                          }
                        }}
                        ref={fileInputRef}
                        name="image"
                        /* hidden this input and use icon below to upload  */
                        style={{ display: "none" }}
                      />

                      {/* Button to trigger file input */}
                      <button
                        type="button"
                        onClick={handleButtonClick}
                        className="w-full h-full flex justify-center items-center"
                      >
                        {showModalAddSection?.showModalAddSection?.isEdit &&
                        typeof watch(`ingredients.${0}.image`) == "string" &&
                        showModalAddSection?.targetId ? (
                          <div className="p-1 w-full h-full flex justify-center items-center">
                            <img
                              src={watch(
                                `ingredients.${blockIndex}.previewImgOrVideo`
                              )}
                              alt={watch(
                                `ingredients.${blockIndex}.previewImgOrVideo`
                              )}
                              className="h-full "
                            />
                          </div>
                        ) : block?.image ? (
                          block?.previewImgOrVideo !== null &&
                          (block?.previewImgOrVideo?.endsWith(".png") ||
                            block?.image?.type?.startsWith("image/")) ? (
                            <div className="p-1 w-full h-full flex justify-center items-center">
                              <img
                                src={block?.previewImgOrVideo}
                                alt={block?.previewImgOrVideo}
                                className="h-full "
                              />
                            </div>
                          ) : (
                            <>
                              {/* <div className="p-1 w-full h-full flex justify-center items-center">
                              {block?.previewImgOrVideo}
                            </div> */}
                            </>
                          )
                        ) : (
                          <UploadImagesIcon
                            className="w-6 h-6"
                            title="تغيير صورة "
                          />
                        )}
                      </button>
                    </div>
                    {errors?.ingredients &&
                      errors?.ingredients[blockIndex]?.image && (
                        <div className=" w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured">
                          <span>
                            {errors?.ingredients[blockIndex]?.image?.message}
                          </span>
                        </div>
                      )}
                    {/* <UploadImages
                      width={"w-[100%]"}
                      image={block?.img}
                      setImage={(selectedIcon) => {
                        handleImgChange(selectedIcon, blockIndex, blockIndex);
                      }}
                      saveImgType={false}
                      ImageSize={"المقاس المقترح: 430 * 110"}
                      reload={reload}
                      setReload={setReload}
                      fetchUrl={
                        "https://api.noor.sa/api/dashboard/common-questions/common-question-background"
                      }
                    /> */}
                  </div>
                </section>
              </>
            );
          })}
        </>
      )
    );
  };
  return (
    <>
      {/* Backdrop */}
      <div
        className={`${styles.backdrop} z-20`}
        onClick={() => setShowModalAddSection({ show: false, type: "" })}
      />
      <div
        className={`z-[5000] fixed top-[90px] left-[50%]  translate-x-[-50%]  max-w-[750px]   w-[90%]  pb-[40px] bg-white rounded-lg`}
      >
        {/* title of modal */}
        <div
          className={`w-full h-11 ${
            showModalAddSection?.isEdit
              ? "bg-[#FFDE06]"
              : "bg-unnamed_color_055345"
          }   flex justify-center items-center px-6 ${styles.modal_title}`}
        >
          <h4
            className={`text-[16px] ${
              showModalAddSection?.isEdit
                ? "text-unnamed_color_055345"
                : "text-white"
            }  font-medium flex-1 text-center`}
          >
            {showModalAddSection?.isEdit ? `تعديل slider ` : " اضافة  slider"}
          </h4>

          <div
            className="cursor-pointer "
            onClick={() => setShowModalAddSection({ show: false, type: "" })}
          >
            <CloseIcon
              className={`${
                showModalAddSection?.isEdit
                  ? "close-icon-black"
                  : "close-icon-white"
              }`}
            />
          </div>
        </div>
        <form
          className=" w-full  max-h-[600px] overflow-y-auto h-fit  pb-[40px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="py-[15px] px-[25px] mt-[20px] ">
            <>
              <div className="w-full border-[1px] rounded-[2px] border-unnamed_color_C6C6C6 h-14 flex justify-start items-center gap-3 p-3 bg-unnamed_color_f6f6f6 add-new-section-title-shadow ">
                <BlogIcon className="blog-icon" />
                <input
                  className="w-full h-full bg-transparent placeholder:text-[#C6C6C6] text-lg font-normal text-black"
                  type="text"
                  placeholder="اسم القسم"
                  {...register("title")}
                />
              </div>
              <div className=" w-full py-[10px]  flex justify-start  items-start gap-[6px] text-ured">
                <span>{errors?.title?.message}</span>
              </div>
            </>
          </div>
          {programAdvantageBlock()}
          {/* content of modal */}
          {/* Add more item button */}
          <div className="!px-[25px] my-[30px]">
            <div
              onClick={() =>
                // handleAddNewItemProgramAdvantage(blockIndex)
                appendIngredientField({
                  link: "",
                  image: "",
                  status: true,
                })
              }
              title="اضافه سلايدر جديد"
              className="w-[100%]  h-12 border-[2px]  border-dashed border-unnamed_color_119184   bg-[#FFEF]  rounded flex justify-center items-center cursor-pointer"
            >
              <PlusIcon className="add-icon" />
            </div>
          </div>
          {showSaveBT ? (
            <button
              type="submit"
              className="w-[154px] h-14 m-auto flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-unnamed_color_055345 rounded "
            >
              <span>
                <SaveIcon />
              </span>
              <span className="text-white text-lg font-medium ">حفظ</span>
            </button>
          ) : (
            <button
              type="button"
              className="w-[154px] !cursor-wait h-14 m-auto flex justify-center items-center gap-2 p-3  disabled:cursor-not-allowed bg-unnamed_color_055345 rounded "
            >
              <span>
                <SaveIcon />
              </span>
              <span className="text-white text-lg font-medium ">حفظ</span>
            </button>
          )}
        </form>
      </div>
    </>
  );
};

export default AddNewSlider;
