import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let PagesSelectDataThunk = createAsyncThunk(
    "pages/PagesSelectDataThunk",
    async (arg, ThunkApi) => {
        let { rejectWithValue } = ThunkApi;
try {
			let url = `/api/select_menu/pages`;

			let res = await axios.get(url);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
    }
);
