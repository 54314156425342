import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let UpdateContactUsDataThunk = createAsyncThunk(
	"myAccount/UpdateContactUsDataThunk",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.put(`/admin/settings`, arg);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
