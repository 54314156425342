import React, { Fragment, useContext } from "react";
import Context from "../../context/Context";
import ReactDom from "react-dom";

// import css styles
import styles from "./LoadingModal.module.css";

// icon
import { Loader } from "../loader";

const LoadingComponent = () => {
	// loading modal
	const loadingModalContext = useContext(Context);
	const { loadingValue } = loadingModalContext;

	return (
		<div>
			{/* Backdrop */}
			<div className={`${styles.backdrop} z-50`} />
			<div
				className={` ${styles.z_index_100} fixed left-0 top-0  flex justify-center items-center w-full h-full rounded-lg`}>
				{/* content of modal */}
				<div className='h-full w-full flex justify-center items-center gap-4'>
					<h5 className=' text-xl font-medium text-red'>{loadingValue}</h5>
					<div className='flex justify-center items-center'>
						<Loader />
					</div>
				</div>
			</div>
		</div>
	);
};

const LoadingModal = () => {
	return (
		<Fragment>
			{ReactDom.createPortal(
				<LoadingComponent />,
				document.getElementById("loading-modal")
			)}
		</Fragment>
	);
};

export default LoadingModal;
