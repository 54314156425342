import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";

let AlertDialog = ({ open, setOpen, handleDelete, setDeleteId, deleteId }) => {
	const handleClose = () => {
		setOpen(false);
		setDeleteId(0);
	};
	let handleDeleteTarget = () => {
		handleDelete(deleteId);
		handleClose();
	};
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title' className=''>
					هل أنت متأكد من حذف العنصر
				</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						لا يمكن استرجاع العنصر بعد حذفه
					</DialogContentText>
				</DialogContent>
				<DialogActions className=' !flex !justify-center !items-center !gap-[15px]'>
					<Button
						className=' !bg-unnamed_color_055345 !text-white !m-0 !px-[10px] py-[8px]'
						onClick={handleClose}>
						عدم الموافقة
					</Button>
					<Button
						className=' !bg-[red] !text-white !m-0 !px-[10px] py-[8px]'
						onClick={handleDeleteTarget}
						autoFocus>
						حذف
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
export default React.memo(AlertDialog);
