import { createSlice } from "@reduxjs/toolkit";

import { GetSiteLogoThunk } from "../Thunk/GetSiteLogoThunk";
import { UpdateSiteLogoThunk } from "../Thunk/UpdateSiteLogoThunk";

let initState = {
	reload: true,
	logo: null,
	error: null,
};

let ChangeSiteLogoReducer = createSlice({
	name: "changeSiteLogo",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(GetSiteLogoThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(GetSiteLogoThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.logo = action.payload.data?.url;
			})

			.addCase(UpdateSiteLogoThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(UpdateSiteLogoThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.logo = action.payload.data;
			})
			.addCase(UpdateSiteLogoThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.error.message;
			});
	},
});
export default ChangeSiteLogoReducer.reducer;
