import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let UpdateTitleMainPagesSectionThunk = createAsyncThunk(
  "mainPage/UpdateTitleMainPagesSectionThunk",
  async (arg, ThunkApi) => {
    let { rejectWithValue } = ThunkApi;
    try {
      let formData = new FormData();
      formData.append("title", arg?.title);
      // formData.append("type", arg?.type);
      //api/admin/pages/4/sections {{URL}}/api/main_page_name?key=electronic_services&value=test
      let res = await axios.patch(
        `/api/main_page_name?key=${arg?.type}&value=${arg?.title}`,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for FormData
          },
        }
      );
      // `/api/admin/pages/${arg?.id}/sections`,{...arg});

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
