import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let OneSectionThunk = createAsyncThunk(
  "section/OneSectionThunk",
  async (arg, ThunkApi) => {
    let { rejectWithValue } = ThunkApi;
    try {
       let res = await axios.get(
         `/api/admin/pages/${arg?.id}/sections/${arg?.targetId}`
       );
      // console.log(res.data);
      return res.data;
    } catch (error) {
      // console.log(error.response.data);
      return rejectWithValue(error.response.data);
    }
  }
);
