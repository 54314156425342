import * as React from "react";
// components

// css styles
import { AddCircle } from "@mui/icons-material";
import { useState } from "react";
import AddNewSection from "../addNewSection/AddNewSection";
import styles from "./AddNewBox.module.css";
// ---------------------------------------------------------------------


const AddNewBox = ({ commonQuestions, setReload, reload, loading }) => {
	const [showAddSectionCom, setShowAddSectionCom] = useState(false);



	// handle Open add new question section
	const handleOpenAddNewQuestionSection = () => {
		setShowAddSectionCom(true);
	};
	// ------------------------------------------------------



	return (
    <section className="flex justify-start flex-col items-start gap-10 my-[40px]">
      <>
        {showAddSectionCom && (
          <div
            className={` w-full flex justify-center items-center flex-col h-[490px] overflow-y-auto p-5 bg-unnamed_color_ebebeb rounded-[10px]  ${styles.question_content_scroll}`}
          >
            <AddNewSection
              showAddSectionCom={showAddSectionCom}
              setShowAddSectionCom={setShowAddSectionCom}
              reload={reload}
              setReload={setReload}
            />
          </div>
        )}
        {!showAddSectionCom && (
          <button
            onClick={handleOpenAddNewQuestionSection}
            className=" w-full h-[66px] border-[2px] border-solid border-unnamed_color_055345 hover:bg-[#FFDE06]  transition rounded-lg cursor-pointer flex flex-col justify-center items-center text-center text-lg text-unnamed_color_055345 font-medium "
          >
            <AddCircle className=" !text-unnamed_color_055345" />
            اضافة قسم جديد
          </button>
        )}
      </>
    </section>
  );
};

export default AddNewBox;
