import { createSlice } from "@reduxjs/toolkit";
import { AddTextSectionThunk } from "../Thunk/AddTextSectionThunk";
import { AllSectionsPagThunk } from "../Thunk/AllSectionsPagThunk";
import { OneSectionThunk } from "../Thunk/OneSectionThunk";
import { PagesSelectDataThunk } from "../Thunk/PagesSelectDataThunk";

let initState = {
  code: null,
  reload: true,
  sectionData: [],
  currentPage: 1,
  lastPage: 1,
  currentSectionData: null,
};

let SectionsReducer = createSlice({
  name: "section",

  initialState: initState,
  reducers: {
    setSectionState: (state, action) => {
      state.code = null;
      state.reload = true;
      state.pagesData = [];
      state.currentPage = 1;
      state.lastPage = 1;
      state.currentSectionData = null;
    },
    setCurrentSectionDataState: (state, action) => {

      state.currentSectionData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // =======AllPagesThunk data===========
      .addCase(AllSectionsPagThunk.pending, (state, action) => {
        state.reload = true;
      })
      .addCase(AllSectionsPagThunk.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.reload = false;
        state.sectionData = action.payload?.data;
        state.currentPage = action.payload.meta.current_page;
        state.lastPage = action.payload.meta.last_page;
      })
      .addCase(AllSectionsPagThunk.rejected, (state, action) => {})
      // =======OneSectionThunk data===========
      .addCase(OneSectionThunk.pending, (state, action) => {})
      .addCase(OneSectionThunk.fulfilled, (state, action) => {
        state.currentSectionData = action.payload?.data;
      })
      .addCase(OneSectionThunk.rejected, (state, action) => {})
      // =======PagesSelectDataThunk data===========
      .addCase(PagesSelectDataThunk.pending, (state, action) => {})
      .addCase(PagesSelectDataThunk.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.pagesSelectData = action.payload?.data;
      })
      .addCase(PagesSelectDataThunk.rejected, (state, action) => {})
      // =======AddTextSectionThunk data===========
      .addCase(AddTextSectionThunk.pending, (state, action) => {})
      .addCase(AddTextSectionThunk.fulfilled, (state, action) => {
        // console.log(action.payload);
        // state.pagesSelectData = action.payload?.data;
      })
      .addCase(AddTextSectionThunk.rejected, (state, action) => {});
  },
});

export default SectionsReducer.reducer;

export let { setSectionState, setCurrentSectionDataState } = SectionsReducer.actions;
