import { configureStore } from "@reduxjs/toolkit";

import ContactUsSettingReducer from "./Reducers/ContactUsSettingReducer";
import ChangeSiteLogoReducer from "./Reducers/ChangeSiteLogoReducer";
import ChangePasswordReducer from "./Reducers/ChangePasswordReducer";
import SocialLinksReducer from "./Reducers/SocialLinksReducer";
import MyAccountReducer from "./Reducers/MyAccountReducer";
import SectionsReducer from "./Reducers/SectionsReducer";
import HomePageReducer from "./Reducers/HomePageReducer";
import ContactReducer from "./Reducers/ContactReducer";
import LoginReducer from "./Reducers/LoginReducer";
import PageReducer from "./Reducers/PageReducer";

export let Store = configureStore({
	reducer: {
		ContactUsSettingReducer,
		ChangeSiteLogoReducer,
		ChangePasswordReducer,
		SocialLinksReducer,
		MyAccountReducer,
		HomePageReducer,
		SectionsReducer,
		ContactReducer,
		LoginReducer,
		PageReducer,
	},
});
