import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import useFetch from "../../Hooks/UseFetch";

// import icons and images
import { ChangeImage, GoArrow } from "../../assets/icons";
import { SubScribesBanner } from "../../assets/images";

// import css styles
import "../../index.css";
import { Loader } from "../loader";
// import styles from "./SubscribesCountBanner.module.css";

const SubscribesCountBanner = () => {
	const navigate = useNavigate();

	// Fetch data from API
	const { fetchedData, loading } = useFetch(
		"https://api.noor.sa/api/dashboard/profile"
	);

	// handle upload banner icon style
	const fileInputRef = React.createRef();

	const handleButtonClick = () => {
		// Trigger a click event on the hidden file input when the button is clicked
		fileInputRef.current.click();
	};

	// to handle upload banners
	const [banner, setBanner] = useState(null);
	const [imagePreview, setImagePreview] = useState(SubScribesBanner);

	// to handle upload banner file
	const handleFileChange = (event) => {
		const file = event.target.files[0];
		setBanner(file);

		// Create a temporary URL for the selected file and set it as the imagePreview state
		const imageUrl = URL.createObjectURL(file);
		setImagePreview(imageUrl);
	};

	return (
		<div className='relative -mb-5'>
			{loading ? (
				<div className='relative flex justify-center items-center h-[317px]'>
					<Loader />
				</div>
			) : (
				<div className='relative flex justify-center items-center h-[317px]'>
					{/* banner images */}
					<img
						className='h-full'
						src={imagePreview && imagePreview}
						alt=''
						loading='lazy'
					/>

					{/* Subscribe count info */}
					<div className='absolute'>
						<div className=' w-[324px]  flex flex-col items-center  '>
							<p className='text-2xl text-[#FFFFFF] font-Tajawal font-medium mb-2'>
								طلبات الإشتراك
							</p>
							<div className=' relative w-full h-[83px] flex justify-center items-center  bg-[#FFDE06] rounded-3xl'>
								<p className='text-5xl text-[#200407] font-Tajawal font-bold'>
									{fetchedData?.data?.subscription_number}
								</p>
								<div className=' absolute left-0 bottom-0 pl-3'>
									<GoArrow
										className='cursor-pointer'
										onClick={() => navigate("/subscribes")}
									/>
								</div>
							</div>
						</div>
					</div>

					{/* change banner icon*/}
					<div className=' absolute left-[70px] bottom-[70px] '>
						{/*  Hidden file input */}
						<input
							type='file'
							onChange={handleFileChange}
							ref={fileInputRef}
							style={{ display: "none" }}
						/>

						{/* Button to trigger file input */}
						<button onClick={handleButtonClick}>
							<ChangeImage className='w-6 h-6' title='قم بتغيير البانر' />
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default SubscribesCountBanner;
