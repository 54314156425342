import React from "react";
import ReactDom from "react-dom";
import AddNewFile from "../AddNewFile/AddNewFile";
import AddNewImgOrVideo from "../AddNewImgOrVideo/AddNewImgOrVideo";
import AddNewImgOrVideoAndTest from "../AddNewImgOrVideoAndTest/AddNewImgOrVideoAndTest";
import AddNewQuestion from "../AddNewQuestion/AddNewQuestion";
import AddNewSlider from "../AddNewSlider/AddNewSlider";
import AddNewText from "../AddNewText/AddNewText";

// icon


let reload = false;
  let setReload = () => {
    "setReload";
  };
const AddNewsSectionModal = ({ showModalAddSection, setShowModalAddSection}) => {
	// console.log(showModalAddSection)

	return (
    <>
      {ReactDom.createPortal(
        showModalAddSection?.show == true && (
          <>
            {showModalAddSection?.type == "qs" && (
              <AddNewQuestion
                {...{
                  reload,
                  setReload,
                  showModalAddSection,
                  setShowModalAddSection,
                }}
              />
            )}
            {showModalAddSection?.type == "slider" && (
              <AddNewSlider
                {...{
                  reload,
                  setReload,
                  showModalAddSection,
                  setShowModalAddSection,
                }}
              />
            )}
            {showModalAddSection?.type == "image_video" && (
              <AddNewImgOrVideo
                {...{
                  reload,
                  setReload,
                  showModalAddSection,
                  setShowModalAddSection,
                }}
              />
            )}
            {showModalAddSection?.type == "image_video_title" && (
              <AddNewImgOrVideoAndTest
                {...{
                  reload,
                  setReload,
                  showModalAddSection,
                  setShowModalAddSection,
                }}
              />
            )}
            {showModalAddSection?.type == "text" && (
              <AddNewText
                {...{
                  reload,
                  setReload,
                  showModalAddSection,
                  setShowModalAddSection,
                }}
              />
            )}
            {showModalAddSection?.type == "files" && (
              <AddNewFile
                {...{
                  reload,
                  setReload,
                  showModalAddSection,
                  setShowModalAddSection,
                }}
              />
            )}
          </>
        ),
        document.getElementById("add-new-question-modal")
      )}
      {/* <Fragment>
			{ReactDom.createPortal(
				<AddNewQuestion {...{ reload,setReload,showModalAddSection, setShowModalAddSection}} />,
				document.getElementById("add-new-question-modal")
			)}
		</Fragment> */}
    </>
  );
};

export default AddNewsSectionModal;
