import React, { useState } from "react";
import Context from "./Context";

export const ArticleMoreInfo = React.createContext();
const ContextProvider = (props) => {
	// handle preview video modal
	const [openPreviewModal, setOpenPreviewModal] = useState(false);
	const [videoSrc, setVideoSrc] = useState(null);

	// handle preview video or images
	const [previewImgOrVideo, setPreviewImgOrVideo] = useState(null);
	// ---------------------------------------------------------------------

	// handle add more info modal for items program advantage and edit modal
	const [openAddMoreInfoModal, setOpenAddMoreInfoModal] = useState(false);
	const [openEditMoreInfoModal, setOpenEditMoreInfoModal] = useState(false);
	const [moreInfoDescription, setMoreInfoDescription] = useState(null);
	const [moreInfoContentIndex, setMoreInfoContentIndex] = useState(null);
	const [blockIndex, setBlockIndex] = useState(null);
	// ---------------------------------------------------------------------

	//  handle  Create Package Component
	const [openCreatePackageComponent, setOpenCreatePackageComponent] =
		useState(false);

	// to handle open and close Edit Package Component
	const [openEditPackageComponent, setOpenEditPackageComponent] =
		useState(false);

	const [currentPackage, setCurrentPackage] = useState(null);
	// -------------------------------------------------------------------

	// handle open add new question modal and send data to according
	const [openAddNewQuestion, setOpenAddNewQuestion] = useState(false);

	// handle add questions
	const [questions, setQuestions] = useState([]);
	const [questionTitle, setQuestionTitle] = useState("");
	const [answerQuestion, setAnswerQuestion] = useState("");

	// to handle edit question
	const [isEdit, setIsEdit] = useState(false);
	const [currentQuestions, setCurrentQuestions] = useState([]);
	// ------------------------------------------------------------------------

	// to handle add new question to current questions array
	const [commonQuestionsId, setCommonQuestionsId] = useState(null);
	const [
		addNewQuestionToCommonQuestionsArray,
		setAddNewQuestionToCommonQuestionsArray,
	] = useState(false);
	// ------------------------------------------------------------------------

	// handle add Add New Question Section context
	const [showAddNewQuestionSection, setShowAddNewQuestionSection] =
		useState(false);

	const [commonQuestionsDataArray, setCommonQuestionsDataArray] = useState([]);
	// --------------------------------------------------------------------------

	// handle open Subscribers Orders Info and send the info to it
	const [subscribeOrderInfo, setSubscribeOrderInfo] = useState(null);
	// -------------------------------------------------------------------------

	// this context to open and close customer service info modal
	const [openCustomerServiceModal, setOpenCustomerServiceModal] =
		useState(false);
	const [customerServiceInfo, setCustomerServiceInfo] = useState(null);
	// --------------------------------------------------------------------------

	//  to  handle open and close CreateNewDiscountCodeModal
	const [openCreateNewDiscountCodeModal, setOpenCreateNewDiscountCodeModal] =
		useState(false);
	const [isDiscountCodeEdit, setIsDiscountCodeEdit] = useState(false);
	// --------------------------------------------------------------------------

	// To handle open edit article page
	const [isArticleEdit, setIsArticleEdit] = useState(false);
	const [currentArticle, setCurrentArticle] = useState(null);
	// --------------------------------------------------------------------------

	// to handle show and hidden actions (save or delete modal) alert
	const [deleteUrl, setDeleteUrl] = useState(null);
	const [actionTitle, setActionTitle] = useState(null);
	const [isConfirmed, setIsConformed] = useState(false);
	const [deleteMethod, setDeleteMethod] = useState(null);
	const [deleteReload, setDeleteReload] = useState(false);
	const [isDeleteAllItems, setIsDeleteAllItems] = useState(false);
	const [theItemToBeDeleted, setTheItemToBeDeleted] = useState(null);
	// ---------------------------------------------------------------------------

	// handle loading modal
	const [loadingValue, setLoadingValue] = useState(null);

	// ----------------------------------------------------------

	// text editor context
	const [editorValue, setEditorValue] = useState(null);

	const context = {
		openPreviewModal,
		setOpenPreviewModal,
		videoSrc,
		setVideoSrc,

		// -----------------

		previewImgOrVideo,
		setPreviewImgOrVideo,
		// -----------------

		openAddMoreInfoModal,
		setOpenAddMoreInfoModal,
		openEditMoreInfoModal,
		setOpenEditMoreInfoModal,
		moreInfoDescription,
		setMoreInfoDescription,
		moreInfoContentIndex,
		setMoreInfoContentIndex,
		blockIndex,
		setBlockIndex,
		// ------------------

		openCreatePackageComponent,
		setOpenCreatePackageComponent,
		// --------------------
		openEditPackageComponent,
		setOpenEditPackageComponent,
		currentPackage,
		setCurrentPackage,
		// ----------------------

		openAddNewQuestion,
		setOpenAddNewQuestion,

		questions,
		setQuestions,
		questionTitle,
		setQuestionTitle,
		answerQuestion,
		setAnswerQuestion,

		// ------------
		isEdit,
		setIsEdit,
		currentQuestions,
		setCurrentQuestions,
		// -----------------------

		commonQuestionsId,
		setCommonQuestionsId,
		addNewQuestionToCommonQuestionsArray,
		setAddNewQuestionToCommonQuestionsArray,
		// ------------------------------
		showAddNewQuestionSection,
		setShowAddNewQuestionSection,

		// ----------------------------
		commonQuestionsDataArray,
		setCommonQuestionsDataArray,
		// --------------------------

		subscribeOrderInfo,
		setSubscribeOrderInfo,
		// ---------------------------
		openCustomerServiceModal,
		setOpenCustomerServiceModal,

		customerServiceInfo,
		setCustomerServiceInfo,
		// -------------------------

		isArticleEdit,
		setIsArticleEdit,
		currentArticle,
		setCurrentArticle,
		// ------------------------
		openCreateNewDiscountCodeModal,
		setOpenCreateNewDiscountCodeModal,
		isDiscountCodeEdit,
		setIsDiscountCodeEdit,
		// -----------------------------

		// to handle delete all
		deleteUrl,
		actionTitle,
		isConfirmed,
		deleteReload,
		setDeleteUrl,
		deleteMethod,
		setActionTitle,
		setIsConformed,
		setDeleteReload,
		setDeleteMethod,
		isDeleteAllItems,
		theItemToBeDeleted,
		setIsDeleteAllItems,
		setTheItemToBeDeleted,
		// ------------------------------

		loadingValue,
		setLoadingValue,
		//

		setEditorValue,
		editorValue,
	};

	return <Context.Provider value={context}>{props.children}</Context.Provider>;
};

export default ContextProvider;
