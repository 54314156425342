import React from "react";
import { useNavigate } from "react-router-dom";

// import css styles
import "../../index.css";
import styles from "./LoginPage.module.css";

// import icons and images
import { yupResolver } from '@hookform/resolvers/yup';
import { Email, VpnKey } from "@mui/icons-material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { SlEye } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { LoginThunk } from "../../RTK/Thunk/LoginThunk";
import { EyeIcon } from "../../assets/icons";
import { Logo } from "../../assets/images";

// =====validation===========
const schema = Yup.object().shape({
	email: Yup.string()
		.email("عنوان البريد الإلكتروني غير صالح")
		.required("مطلوب"),
	pass: Yup.string().required("مطلوب"),
});

const LoginPage = () => {
	let dispatch = useDispatch();
	let { code } = useSelector((state) => state.LoginReducer);
	const navigate = useNavigate();
	const [showErrorForm, setShowErrorForm] = React.useState({
		type: false,
		data: "",
	});

	// to handle show and hide password
	const [showPassword, setShowPassword] = React.useState(false);

	// handel path and error form
	useEffect(() => {
		if (localStorage.getItem("token")) {
			navigate("/");
		}
	}, [navigate]);
  const {
    control,
    handleSubmit,
		register,
		setValue,
		watch,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      // name: '',
      email: '',
      pass: '',
    }
  });
const onSubmit = async (data) => {
			dispatch(LoginThunk(data))
				.unwrap()
				.then((data) => {

					navigate("/");
				})
				.catch((error) => {
					// console.log(error)
					if (error?.code !== null) {
						setShowErrorForm({ type: true, data: error?.message });
					}
					// console.log(error);
					// handle error here
				});
  };
	return (
		<div className={`${styles.login_bg}`}>
			<div className='text-center flex  flex-col  justify-center items-center w-[451px] h-full m-auto'>
				<div className='mx-auto flex  justify-center items-center p-[40px] cursor-pointer'>
					<img
						src={Logo}
						alt=''
						loading='lazy'
						onClick={() => window.open("https://noor.sa/")}
					/>
				</div>
				<form className='w-full' onSubmit={handleSubmit(onSubmit)}>
					{/* email */}
					<div className='w-full border-[1px] border-unnamed_color_055345 h-14 rounded-[28px] flex justify-start items-center gap-2 bg-white mb-4 px-5'>
						<Email className=' text-[20px] text-unnamed_color_055345' />
						<input
							// type='email'
							// name='email'
							// onChange={formik.handleChange}
							// value={formik.values.email}
							{...register("email")}
							placeholder='قم بإدخال البريد الالكتروني'
							className=' w-4/5 h-full bg-transparent  placeholder:text-unnamed_color_C6C6C6 text-[15px] font-normal text-black'
						/>
						{errors?.email && errors?.email.message ? (
							<span
								style={{
									width: "100%",
									color: "red",
									fontSize: "15px",
									marginTop: "5px",
								}}>
								{errors?.email.message}
							</span>
						) : null}
					</div>

					{/* password */}
					<div
						className={`w-full h-14 border-[1px] border-unnamed_color_055345 rounded-[28px] flex justify-start items-center gap-2 bg-white ${"mb-6"} px-5`}>
						<VpnKey className=' text-[20px] text-unnamed_color_055345' />
						<input
							type={showPassword ? "text" : "password"}
							// name='pass'
							// onChange={(e)=>{
							// console.log(e)
							// setValue("pass", (e.target.value))
							// }}
							// value={watch("pass")}
														{...register("pass")}

							placeholder='••••••••'
							className='w-4/5 h-full bg-transparent placeholder:text-unnamed_color_C6C6C6 text-[15px] font-normal text-black'
						/>
						{errors?.pass && errors?.pass?.message ? (
							<span
								style={{
									width: "100%",
									color: "red",
									fontSize: "15px",
									marginTop: "5px",
								}}>
								{errors?.pass?.message}
							</span>
						) : null}
				<div className=" w-[25px] h-[25px] flex justify-center items-center">
							{showPassword ? (
							<SlEye
								className='cursor-pointer w-6 h-6 open-eye'
								onClick={() => setShowPassword(!showPassword)}
							/>
						) : (
							<EyeIcon
								className='cursor-pointer'
								onClick={() => setShowPassword(!showPassword)}
							/>
						)}
				</div>

					</div>
					<div className='w-full flex justify-center '>
						<button
							type='submit'
							className={`   w-full h-[53px] rounded-full bg-unnamed_color_055345 transition-[0.3s]  text-white text-lg font-medium`}>
							تسجيل دخول
						</button>
					</div>
					<span
						style={{
							display: showErrorForm?.type === true ? "block" : "none",
							color: "red",
							textAlign: "center",
							width: "100%",
							marginTop: "10px",
						}}>
						{showErrorForm?.data}
					</span>
				</form>
			</div>
		</div>
	);
};

export default LoginPage;
