import { createSlice } from "@reduxjs/toolkit";
import { AddPageThunk } from "../Thunk/AddPageThunk";
import { AllPagesThunk } from "../Thunk/AllPagesThunk";
import { OnePageThunk } from "../Thunk/OnePageThunk";
import { PagesSelectDataThunk } from "../Thunk/PagesSelectDataThunk";
import { handlePriorityOfPageThunk } from "../Thunk/handlePriorityOfPageThunk";

let initState = {
	code: null,
	reload: true,
	pagesData: [],
};

let PageReducer = createSlice({
	name: "pages",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======AllPagesThunk data===========
			.addCase(AllPagesThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(AllPagesThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.pagesData = action.payload?.data;
			})
			.addCase(AllPagesThunk.rejected, (state, action) => {
				state.reload = false;
			}) // =======OnePageThunk data===========
			.addCase(OnePageThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(OnePageThunk.fulfilled, (state, action) => {
				console.log(action.payload);
				state.currentPageData = action.payload?.data;
			})
			.addCase(OnePageThunk.rejected, (state, action) => {
				state.reload = false;
			})
			.addCase(handlePriorityOfPageThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(handlePriorityOfPageThunk.fulfilled, (state, action) => {
				state.reload = false;
			})
			.addCase(handlePriorityOfPageThunk.rejected, (state, action) => {
				state.reload = false;
			}) // =======PagesSelectDataThunk data===========
			.addCase(PagesSelectDataThunk.pending, (state, action) => {})
			.addCase(PagesSelectDataThunk.fulfilled, (state, action) => {
				// console.log(action.payload);
				state.pagesSelectData = action.payload?.data;
			})
			.addCase(PagesSelectDataThunk.rejected, (state, action) => {});
	},
});

export default PageReducer.reducer;

export let { setPageState } = PageReducer.actions;
