import React, { useRef, useState } from "react";
import { UploadIcon } from "../../assets/icons";

const UploadProgramAdvantageItemIcon = ({ icon, setIcon ,iconName }) => {
	const [previewIcon, setPreviewIcon] = useState(null);

	//1- to hide input type file
	const fileInputRef = React.createRef();

	// 2- Trigger a click event on the hidden file input when the button is clicked
	const handleButtonClick = () => {
		fileInputRef.current.click();
	};
	const setIconOnEditRf=useRef(true)

	// to handle upload images files
	const handleUploadImages = (event) => {
		const file = event.target.files[0];
		setIcon(file);

		if (file) {
			const reader = new FileReader();

			reader.onload = function (e) {
				const svgContent = e.target.result;
				setPreviewIcon(svgContent);
			};

			reader.readAsText(file);
		}
	};

	return (
    <div
      className={`${
        previewIcon ? "bg-white" : "bg-unnamed_color_C6C6C6"
      } w-[69px] h-full flex justify-center items-center cursor-pointer  rounded `}
    >
      <input
        type="file"
        name={iconName}
        accept=".svg"
        onChange={handleUploadImages}
        ref={fileInputRef}
        /* hidden this input and use icon below to upload  */
        style={{ display: "none" }}
      />

      {/* Button to trigger file input */}
      <div
        onClick={handleButtonClick}
        className="w-full h-full flex justify-center items-center"
      >
        {icon !== "" && icon !== null && typeof icon == "string" ? (
          <img className=" max-w-[100%] max-h-[100%] " src={icon} alt="" />
        ) : previewIcon ? (
          <div
            dangerouslySetInnerHTML={{ __html: previewIcon }}
            className=" max-w-[100%] max-h-[100%] svg-prov"
          />
        ) : (
          <UploadIcon className="w-6 h-6" />
        )}
      </div>
    </div>
  );
};

export default UploadProgramAdvantageItemIcon;
