import React, { useContext, useEffect, useState } from "react";

// icon
import {
  BlogIcon,
  CloseIcon,
  DeleteIcon,
  ImagesIcon,
  LinkIcon,
  PreviewIcon,
  SaveIcon,
  TextIcon,
  UploadImagesIcon,
} from "../../assets/icons";

import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import * as Yup from "yup";
import Context from "../../context/Context";
import SectionLabel from "../sectionLabel/SectionLabel";
import TextEditorWithForm from "../textEditor/TextEditorWithForm";
import PreviewVideoModal from "../uploadVideo/previewVideo/PreviewVideo";
import styles from "./AddNewImgOrVideoAndTest.module.css";
import { AddImageVideoTitleSectionThunk } from "../../RTK/Thunk/AddImageVideoTitleSectionThunk";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AllSectionsPagThunk } from "../../RTK/Thunk/AllSectionsPagThunk";
import { OneSectionThunk } from "../../RTK/Thunk/OneSectionThunk";
import { setCurrentSectionDataState } from "../../RTK/Reducers/SectionsReducer";
import { useSelector } from "react-redux";
const schema = Yup.object().shape({
  section_title: Yup.string().required("قم بإضافة اسم القسم"),
  text: Yup.string().required("قم بإضافة المحتوى النصي"),
  ingredients: Yup.array()
    // test select options and radio buttons
    .of(
      Yup.object().shape({
        title: Yup.string().required("قم بإضافة عنوان القسم"),
        link: Yup.mixed().optional("قم بإضافة ربط القسم"),
        image: Yup.mixed().required("قم بإضافة صورة القسم"),
        previewImgOrVideo: Yup.mixed().optional("قم بإضافة اسم القسم"),
      })
    ),
});
const AddNewImgOrVideoAndTest = ({
  featuresBlocks,
  reload,
  setReload,
  showModalAddSection,
  setShowModalAddSection,
}) => {
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [videoSrc, setVideoSrc] = useState(false);
  	  let { currentSectionData } = useSelector(
        (state) => state.SectionsReducer
      );

	  let dispatch = useDispatch();
    let params = useParams();
  // from hooks
  const {
    control,
    handleSubmit,
    register,
    setValue,
    watch,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    defaultValues: {
      section_title: "",
      text: "",
      ingredients: [
        {
          title: "",
          image: null,
          previewImgOrVideo: null,
          link: "",
          points: [{ icon: null, point: "", description: "" }],
        },
      ],
    },
  });
  const {
    fields: ingredientsFields,
    append: appendIngredientField,
    remove: removeIngredientField,
  } = useFieldArray({
    control,
    name: "ingredients",
  });

  // console.log("ingredients", watch("ingredients"));

  // console.log(typeof watch(`ingredients.${0}.image`) )
  const fileInputRef = React.createRef();

  // Function to handle file input change
  const handleSetImageInput = (blockIndex, event) => {
    const file = event?.target?.files[0];
    const imgConvert = URL.createObjectURL(file);

    const updatedBlocks = [...watch("ingredients")];
    updatedBlocks[blockIndex].image = file;

    updatedBlocks[blockIndex].previewImgOrVideo = imgConvert;

    setValue("ingredients", updatedBlocks);
  };

  // Function to open the file input dialog
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // handle openPreview video modal
  const handleOpenPreviewVideoModal = (previewImgOrVideo) => {
    setOpenPreviewModal(true);
    setVideoSrc(previewImgOrVideo);
  };


  // set err text default
  useEffect(() => {
    // errors?.page_icon;
    if (watch("text") !== "") {
      setError("text");
    }
  }, [setError, watch("text")]);
  const handleDeleteImages = (blockIndex) => {
    const updatedBlocks = [...watch("ingredients")];
    updatedBlocks[blockIndex].image = null;
    updatedBlocks[blockIndex].previewImgOrVideo = null;
    setValue("ingredients", updatedBlocks);
    fileInputRef.current.value = null;
  };

  // console.log(errors.ingredients);

  // console.log(errors.ingredients[0])
  // get current data target updated
    useEffect(
      (e) => {
        // console.log(e);
        if (showModalAddSection?.isEdit && showModalAddSection?.targetId) {
          dispatch(
            OneSectionThunk({
              id: params?.sectionId,
              targetId: showModalAddSection?.targetId,
            })
          );
        }
      },
      [showModalAddSection]
    );
  useEffect(() => {
    if (currentSectionData !== null) {
      // console.log(currentSectionData)
      setValue("section_title", currentSectionData?.title);
      setValue("text", currentSectionData?.details?.text);
      setValue(`ingredients.${0}.title`, currentSectionData?.details?.title);
      setValue(`ingredients.${0}.image`, currentSectionData?.details?.file);
      setValue(`ingredients.${0}.link`, currentSectionData?.details?.link||"");
      setValue(
        `ingredients.${0}.previewImgOrVideo`,
        currentSectionData?.details?.file
      );
    }
  }, [currentSectionData]);

		useEffect(() => {
      return () => {
        dispatch(setCurrentSectionDataState());
      };
    }, []);
        const [showSaveBT, setShowSaveBT] = useState(true);

  const onSubmit = async (data) => {
        setShowSaveBT(false);

    dispatch(
      AddImageVideoTitleSectionThunk({
        ...data,
        id: params?.sectionId,
        type: "image_video_title",
        targetId: showModalAddSection?.targetId
          ? showModalAddSection?.targetId
          : null,
      })
    )
      .unwrap()
      .then((data) => {
                            setShowSaveBT(true);

        dispatch(
          AllSectionsPagThunk({
            id: params?.sectionId,
            page: 1,
          })
        );
        setShowModalAddSection({ show: false, type: "" });
      })
      .catch((error) => {
                            setShowSaveBT(true);

    if (error?.data) {
      if (error?.data["image_video_title.title"]) {
        // console.log(error?.data["image_video_title.link"]);
        setError(`ingredients.${0}.title`, {
          message: error?.data["image_video_title.title"],
        });
      }
      if (error?.data["image_video_title.link"]) {
        // console.log(error?.data["image_video_title.link"]);
        setError(`ingredients.${0}.link`, {
          message: error?.data["image_video_title.link"],
        });
      }
      if (error?.data["image_video_title.file"]) {
        // console.log(error?.data["image_video_title.link"]);
        setError(`ingredients.${0}.image`, {
          message: error?.data["image_video_title.file"],
        });
      }
      if (error?.data?.text) {
        setError("text", { message: error?.data?.text });
      }
      if (error?.data?.title) {
        setError("section_title", { message: error?.data?.title });
      }
    }


        // console.log(error);
        // handle error here
      });
  };
  const programAdvantageBlock = () => {
    return (
      ingredientsFields?.length > 0 && (
        <>
          {ingredientsFields.map((block, blockIndex) => {
            return (
              <>
                <section className="py-[15px] px-[25px]" key={blockIndex}>
                  <SectionLabel label={"عنوان"} icon={<TextIcon />} />
                  <div className="w-[94%] min-h-[56px] h-auto bg-[#FFFEF2] rounded-2xl section-box-shadow flex justify-start items-center ">
                    <input
                      type="text"
                      // placeholder='فريق عمل في خدمتكم'
                      // value={block?.title}
                      // name="title"
                      // onChange={(e) =>{
                      //   handleSetTitleInput(e, blockIndex);
                      //   // setError(`ingredients[${blockIndex}].image`);
                      //   // watch(`ingredients[${blockIndex}].image`);
                      //   console.log(
                      //     "watch",
                      //     watch(`ingredients${[blockIndex].title}`)
                      //   );
                      // }}

                      {...register(`ingredients.${blockIndex}.title`)}
                      className="w-full h-full pr-3 text-[16px] font-normal bg-transparent placeholder:text-[#C6C6C6]"
                    />
                  </div>
                  {errors?.ingredients &&
                    errors?.ingredients[blockIndex]?.title && (
                      <div className=" w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured">
                        <span>
                          {errors?.ingredients[blockIndex]?.title?.message}
                        </span>
                      </div>
                    )}
                </section>
                {/* Upload Videos or images  */}
                <section className="py-[15px] px-[25px]">
                  <SectionLabel label={"صورة/ فيديو"} icon={<ImagesIcon />} />

                  {/* Upload image or video Component */}
                  <div className="w-[94%]  flex justify-start items-center gap-4">
                    {/* upload images Component*/}
                    <div className="w-[94%] min-h-[100px] h-auto bg-[#FFFEF2] rounded-2xl section-box-shadow p-4">
                      <div className="w-full  h-[48px] flex justify-center items-center bg-white border-[2px] border-dashed border-unnamed_color_119184  m-auto mb-1 cursor-pointer">
                        <input
                          type="file"
                          accept="image/*,video/*"
                          control={control}
                          onChange={(selectedImageOrVideo) => {
                            // console.log(
                            //   "selectedImageOrVideo",
                            //   selectedImageOrVideo
                            // );
                            // event?.target?.files[0];
                            if (selectedImageOrVideo?.target?.files[0]) {
                              setError(`ingredients.${blockIndex}.image`);
                              handleSetImageInput(
                                blockIndex,
                                selectedImageOrVideo
                              );
                            }
                          }}
                          ref={fileInputRef}
                          name="image"
                          /* hidden this input and use icon below to upload  */
                          style={{ display: "none" }}
                        />

                        {/* Button to trigger file input */}
                        <button
                          type="button"
                          onClick={handleButtonClick}
                          className="w-full h-full flex justify-center items-center"
                        >
                          {showModalAddSection?.isEdit &&
                          typeof watch(`ingredients.${0}.image`) == "string" &&
                          showModalAddSection?.targetId ? (
                            <div className="p-1 w-full h-full flex justify-center items-center">
                              <img
                                src={watch(
                                  `ingredients.${blockIndex}.previewImgOrVideo`
                                )}
                                alt={watch(
                                  `ingredients.${blockIndex}.previewImgOrVideo`
                                )}
                                className="h-full "
                              />
                            </div>
                          ) : block?.image ? (
                            block?.previewImgOrVideo !== null &&
                            (block?.previewImgOrVideo?.endsWith(".png") ||
                              block?.image?.type?.startsWith("image/")) ? (
                              <div className="p-1 w-full h-full flex justify-center items-center">
                                <img
                                  src={block?.previewImgOrVideo}
                                  alt={block?.previewImgOrVideo}
                                  className="h-full "
                                />
                              </div>
                            ) : (
                              <div className="p-1 w-full h-full flex justify-center items-center">
                                {block?.previewImgOrVideo}
                              </div>
                            )
                          ) : (
                            <UploadImagesIcon
                              className="w-6 h-6"
                              title="تغيير صورة "
                            />
                          )}
                        </button>
                      </div>

                      {/* Image Size */}
                      <div className="">
                        <span className="text-[16px] text-[#C6C6C6] font-normal ">
                          المقاس المقترح: 400 * 180
                        </span>
                      </div>
                    </div>

                    {/* Save and delete videos */}
                    <div className="flex justify-start items-center gap-4">
                      {/* preview video */}
                      {showModalAddSection?.isEdit &&
                      typeof watch(`ingredients.${0}.image`) == "string" &&
                      showModalAddSection?.targetId
                        ? watch(`ingredients.${blockIndex}.image`) !== null &&
                          watch(
                            `ingredients.${blockIndex}.previewImgOrVideo`
                          )?.endsWith(".mp4") &&
                          watch(
                            `ingredients.${blockIndex}.previewImgOrVideo`
                          ) !== null && (
                            <button
                              type="button"
                              onClick={() => {
                                // console.log(block?.previewImgOrVideo);
                                handleOpenPreviewVideoModal(
                                  watch(
                                    `ingredients.${blockIndex}.previewImgOrVideo`
                                  )
                                );
                              }}
                              className="block cursor-pointer disabled:cursor-not-allowed"
                            >
                              <PreviewIcon
                                className="w-11 h-11 preview-video-icon"
                                title="معاينة الفيديو"
                              />
                            </button>
                          )
                        : block?.image !== null &&
                          (block?.previewImgOrVideo?.endsWith(".mp4") ||
                            block?.image?.type?.startsWith("video/")) &&
                          block?.previewImgOrVideo !== null && (
                            <button
                              type="button"
                              onClick={() => {
                                console.log(block?.previewImgOrVideo);
                                handleOpenPreviewVideoModal(
                                  block?.previewImgOrVideo
                                );
                              }}
                              className="block cursor-pointer disabled:cursor-not-allowed"
                            >
                              <PreviewIcon
                                className="w-11 h-11 preview-video-icon"
                                title="معاينة الفيديو"
                              />
                            </button>
                          )}
                      {/* delete video */}
                      <button
                        type="button"
                        className="cursor-pointer disabled:cursor-not-allowed"
                        onClick={() => handleDeleteImages(blockIndex)}
                      >
                        <DeleteIcon className="w-6 h-6 " title="حذف الفيديو" />
                      </button>
                    </div>
                  </div>
                  {errors?.ingredients &&
                    errors?.ingredients[blockIndex]?.image && (
                      <div className=" w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured">
                        <span>
                          {errors?.ingredients[blockIndex]?.image?.message}
                        </span>
                      </div>
                    )}
                </section>
                {/* Embed YouTube Video  */}
                <section className="py-[15px] px-[25px]">
                  <SectionLabel
                    label={"رابط فيديو"}
                    icon={<LinkIcon className="link-icon" />}
                  />

                  <div className="w-[94%]  min-h-[100px] h-auto bg-[#FFFEF2] rounded-2xl section-box-shadow px-4 flex justify-center items-center">
                    <input
                      type="text"
                      name="link"
                      // value={block?.link}
                      {...register(`ingredients.${blockIndex}.link`)}
                      // onChange={(e) => handleSetLinkInput(e, blockIndex)}
                      placeholder={"https://www.kkk.youtube.com"}
                      className="w-full h-11 bg-white m-auto p-5 !border-[1px] !border-solid !border-unnamed_color_119184  rounded-lg"
                    />
                  </div>
                  {errors?.ingredients &&
                    errors?.ingredients[blockIndex]?.link && (
                      <div className=" w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured">
                        <span>
                          {errors?.ingredients[blockIndex]?.link?.message}
                        </span>
                      </div>
                    )}
                </section>
              </>
            );
          })}
        </>
      )
    );
  };
  return (
    <>
      <div className="  px-[30px]  ">
        {/* Backdrop */}
        <div
          className={`${styles.backdrop} z-20`}
          onClick={() => setShowModalAddSection({ show: false, type: "" })}
        />
        <form
          className={`z-[5000] absolute  top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] max-w-[750px]   w-[90%]  bg-white rounded-[10px]`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <>
            <div
              className={`w-full h-11 ${
                showModalAddSection?.isEdit
                  ? "bg-[#FFDE06]"
                  : "bg-unnamed_color_055345"
              }   flex justify-center items-center px-6 ${styles.modal_title}`}
            >
              <h4
                className={`text-[16px] ${
                  showModalAddSection?.isEdit
                    ? "text-unnamed_color_055345"
                    : "text-white"
                }  font-medium flex-1 text-center`}
              >
                {showModalAddSection?.isEdit
                  ? ` تعديل نص و صورة/ فيديو `
                  : "  اضافة نص و صورة/ فيديو"}
              </h4>
              <div
                className="cursor-pointer "
                onClick={() =>
                  setShowModalAddSection({ show: false, type: "" })
                }
              >
                <CloseIcon
                  className={`${
                    showModalAddSection?.isEdit
                      ? "close-icon-black"
                      : "close-icon-white"
                  }`}
                />
              </div>
            </div>
            {/* title of modal */}
            <div className=" w-full  max-h-[600px] overflow-y-auto h-fit  pb-[40px]">
              <div className="py-[15px] px-[25px] mt-[20px] ">
                <>
                  <div className="w-full border-[1px] rounded-[2px] border-unnamed_color_C6C6C6 h-14 flex justify-start items-center gap-3 p-3 bg-unnamed_color_f6f6f6 add-new-section-title-shadow ">
                    <BlogIcon className="blog-icon" />
                    <input
                      className="w-full h-full bg-transparent placeholder:text-[#C6C6C6] text-lg font-normal text-black"
                      type="text"
                      placeholder="اسم القسم"
                      {...register("section_title")}
                    />
                  </div>
                  <div className=" w-full  flex justify-start  items-start gap-[6px] text-ured">
                    <span>{errors?.section_title?.message}</span>
                  </div>
                </>
              </div>

              {programAdvantageBlock()}
              <div className="py-[15px] px-[25px] ">
                {" "}
                <TextEditorWithForm
                  placeholder={" المحتوى النصي للقسم المحتوى"}
                  height={"300px"}
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  name="text"
                />
                <div className=" w-full  mt-[10px] flex justify-start mb-[10px] items-start gap-[6px] text-ured">
                  <span>{errors?.text?.message}</span>
                </div>
              </div>
              {/* content of modal */}
              {showSaveBT ? (
                <button
                  type="submit"
                  className="w-[154px] h-14 m-auto flex justify-center items-center gap-2 p-3 cursor-pointer disabled:cursor-not-allowed bg-unnamed_color_055345 rounded "
                >
                  <span>
                    <SaveIcon />
                  </span>
                  <span className="text-white text-lg font-medium ">حفظ</span>
                </button>
              ) : (
                <button
                  type="button"
                  className="w-[154px] !cursor-wait h-14 m-auto flex justify-center items-center gap-2 p-3  disabled:cursor-not-allowed bg-unnamed_color_055345 rounded "
                >
                  <span>
                    <SaveIcon />
                  </span>
                  <span className="text-white text-lg font-medium ">حفظ</span>
                </button>
              )}
            </div>
          </>
        </form>
      </div>
      <PreviewVideoModal
        {...{ openPreviewModal, setOpenPreviewModal, videoSrc, setVideoSrc }}
      />
    </>
  );
};

export default AddNewImgOrVideoAndTest;
