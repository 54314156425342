import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let AddSliderSectionThunk = createAsyncThunk(
  "section/AddSliderSectionThunk",
  async (arg, ThunkApi) => {
    let { rejectWithValue } = ThunkApi;
    try {
      let formData = new FormData();
      if (arg?.targetId) {
        formData.append("title", arg?.title);
        formData.append("type", arg?.type);
        arg?.ingredients?.forEach((ingredient, index) => {
          // console.log(ingredient)
          if (ingredient.id) {
            formData.append(`sliders[${index}][id]`, ingredient.id);
          }
          if (typeof ingredient?.image !== "string") {
            formData.append(`sliders[${index}][image]`, ingredient?.image);
          }
          if (ingredient.status == true) {
            // console.log("status=true");
            formData.append(`sliders[${index}][status]`, 1);
          }
          if (ingredient.status == false) {
            // console.log("status=false");
            formData.append(`sliders[${index}][status]`, 0);
          }
          // formData.append(
          //   `sliders[${index}][status]`,
          //   ingredient.status == "true" ? "sss" : "errr"
          // );
          // console.log(ingredient.status);

          formData.append(`sliders[${index}][link]`, ingredient.link);
        });
        // arg?.targetDeleteIdItem=[1,3,5]
        arg?.targetDeleteIdItem?.forEach((id, index) => {
          formData.append(`deleted_sliders_ids[${index}]`, id);
        });
      } else {
        formData.append("title", arg?.title);
        formData.append("type", arg?.type);
        arg?.ingredients?.forEach((ingredient, index) => {
          console.log(ingredient)
          // if (ingredient.image && ingredient.image !== "[object FileList]") {
          formData.append(`sliders[${index}][image]`, ingredient?.image);
          // }
             if (ingredient.status == true) {
              //  console.log("status=true");

               formData.append(`sliders[${index}][status]`, 1);
             }
             if (ingredient.status == false) {
              //  console.log("status=false");

               formData.append(`sliders[${index}][status]`, 0);
             }

          // title most be link
          formData.append(`sliders[${index}][link]`, ingredient.link);
        });
      }
      let res = await axios.post(
        `/api/admin/pages/${arg?.id}/sections${
          arg?.targetId !== null ? "/" + arg?.targetId : ""
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for FormData
          },
        }
      );
      // `/api/admin/pages/${arg?.id}/sections`,{...arg});

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// // Sample ingredients array
// const ingredients = [
//   { status: true, image: '[object FileList]', link: 'image-1' },
//   { image: '[object FileList]', link: 'image-2' }
// ];

// // Create a new FormData object
// const formData = new FormData();

// // Map through the ingredients array
// ingredients.forEach((ingredient, index) => {
//   if (ingredient.image && ingredient.image !== '[object FileList]') {

//     formData.append(`image-${index + 1}`, ingredient.image);
//   }

//   formData.append(`status-${index + 1}`, ingredient.status ? 'true' : 'false');
//   // Adding the link property:
//   formData.append(`link-${index + 1}`, ingredient.link);
// });
