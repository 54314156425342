import { createSlice } from "@reduxjs/toolkit";
import { EditMyAccountThunk } from "../Thunk/EditMyAccountThunk";
import { GetMyAccountDataThunk } from "../Thunk/GetMyAccountDataThunk";

let initState = {
	reload: true,
	myAccountData: {
		name: "",
		email: "",
		avatar: "",
	},
	error: null,
};

let MyAccountReducer = createSlice({
	name: "myAccount",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(GetMyAccountDataThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(GetMyAccountDataThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.myAccountData = action.payload.data;
				localStorage.setItem("userName", action.payload.data?.name);
				localStorage.setItem("avatar", action.payload.data?.avatar);
			})

			.addCase(EditMyAccountThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(EditMyAccountThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.myAccountData = action.payload.data;
			})
			.addCase(EditMyAccountThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.error.message;
			});
	},
});
export default MyAccountReducer.reducer;
