import { createSlice } from "@reduxjs/toolkit";
import { GetContactUsDataThunk } from "../Thunk/GetContactUsDataThunk";
import { UpdateContactUsDataThunk } from "../Thunk/UpdateContactUsDataThunk";

let initState = {
	reload: true,
	contactUsData: {
		address: "",
		email: "",
		phone: "",
	},
	error: null,
};

let ContactUsSettingReducer = createSlice({
	name: "ContactUsSetting",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(GetContactUsDataThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(GetContactUsDataThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.contactUsData = action.payload.data;
			})

			.addCase(UpdateContactUsDataThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(UpdateContactUsDataThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.contactUsData = action.payload.data;
			})
			.addCase(UpdateContactUsDataThunk.rejected, (state, action) => {
				state.reload = false;
				state.error = action.error.message;
			});
	},
});
export default ContactUsSettingReducer.reducer;
