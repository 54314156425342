import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let UpdateSiteLogoThunk = createAsyncThunk(
	"myAccount/UpdateSiteLogo",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let formData = new FormData();
			formData.append("logo", arg);
			let res = await axios.post(`/api/admin/site_logo`, formData);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
