import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let AddImageVideoSectionThunk = createAsyncThunk(
  "section/AddImageVideoSectionThunk",
  async (arg, ThunkApi) => {
    let { rejectWithValue } = ThunkApi;
    try {
      let formData = new FormData();
      formData.append("title", arg?.title);
      formData.append("type", arg?.type);
      if (typeof arg?.ingredients[0]?.image !== "string") {
        formData.append("image_video[file]", arg?.ingredients[0]?.image);
      }
      // if(arg?.ingredients[0]?.link){
              formData.append("image_video[link]", arg?.ingredients[0]?.link);

      // }
      //api/admin/pages/4/sections
      let res = await axios.post(
        `/api/admin/pages/${arg?.id}/sections${
          arg?.targetId !== null ? "/" + arg?.targetId : ""
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for FormData
          },
        }
      );
      // `/api/admin/pages/${arg?.id}/sections`,{...arg});

      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
