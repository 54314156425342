import React, { Fragment, useContext } from "react";
import ReactDom from "react-dom";
import Context from "../../context/Context";

// import css styles
import styles from "./SaveOrDeleteModal.module.css";

// icon
import { OKIcon } from "../../assets/icons";
import { useEffect } from "react";

const SaveOrDeleteAlert = () => {
	// to handle show and hidden actions (save or delete modal) alert
	const actionModalContext = useContext(Context);
	const { actionTitle, setActionTitle } = actionModalContext;

	// to handle close actions (save or delete modal) after three second
	useEffect(() => {
		const debounce = setTimeout(() => {
			if (actionTitle !== null) {
				setActionTitle(null);
			}
		}, 2000);

		return () => {
			clearTimeout(debounce);
		};
	}, [actionTitle]);
	return (
		<div>
			{/* Backdrop */}
			<div className={`${styles.backdrop} z-50`} />
			<div
				className={` ${styles.z_index_100} fixed left-[10%] bottom-[10%]  w-[324px] h-[86px] bg-[#3AE374] rounded-lg`}>
				{/* content of modal */}
				<div className='h-full flex justify-center items-center gap-4'>
					<div className='w-6 h-6 rounded-full bg-white'>
						<OKIcon />
					</div>
					<h5 className=' text-xl font-medium text-[#FFFFFF]'>{actionTitle}</h5>
				</div>
			</div>
		</div>
	);
};

const SaveOrDeleteModal = () => {
	return (
		<Fragment>
			{ReactDom.createPortal(
				<SaveOrDeleteAlert />,
				document.getElementById("save-or-delete-modal")
			)}
		</Fragment>
	);
};

export default SaveOrDeleteModal;
