import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export let GetContactUsDataThunk = createAsyncThunk(
	"ContactUsSetting/GetContactUsData",
	async (arg, ThunkApi) => {
		let { rejectWithValue } = ThunkApi;
		try {
			let res = await axios.get(`/admin/settings`);

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
