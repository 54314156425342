import { createSlice } from "@reduxjs/toolkit";
import { GetSocialLinksThunk } from "../Thunk/GetSocialLinksThunk";
import { UpdateSocialLinksThunk } from "../Thunk/UpdateSocialLinksThunk";

let initState = {
	reload: true,
	socialLinksArr: [],
};

let SocialLinksReducer = createSlice({
	name: "socialLinks",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// =======contact===========
			.addCase(GetSocialLinksThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(GetSocialLinksThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.socialLinksArr = action.payload.data;
			})
			.addCase(GetSocialLinksThunk.rejected, (state, action) => {
				state.reload = false;
			})

			// Handle  update social links action
			.addCase(UpdateSocialLinksThunk.pending, (state) => {
				state.reload = true;
			})
			.addCase(UpdateSocialLinksThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.socialLinksArr = action.payload.data;
			})
			.addCase(UpdateSocialLinksThunk.rejected, (state) => {
				state.reload = false;
			});
	},
});

export default SocialLinksReducer.reducer;

// export { }=LoginReducer.actions
